import type { ResourceScope } from '@obvio/server'

export const RESOURCE_PREFIXES: Partial<Record<ResourceScope, string>> = {
  page: '/',
  article: '/blog/',
}

export const PATHS = [
  { href: '/#oferta', title: 'Oferta' },
  { href: '/#lokalizacja', title: 'Lokalizacja' },
  { href: '/#o-nas', title: 'O nas' },
  { href: '/#start', title: 'Jak zacząć' },
  { href: '/#firmy', title: 'Dla firm' },
  { href: '/blog', title: 'Blog' },
  { href: '/#kontakt', title: 'Kontakt' },
]
