import { Context, Link, notDesktop, styled } from "@obvio/app";
import { SvgCloseMenu, SvgMenuGolf } from "@obvio/svg";
import { FadeIn, useUi } from "@obvio/template";
import { Button, Drawer, Stack } from "@obvio/ui";
import { DrawerContent } from "@obvio/ui/Drawer/components";
import { motion } from "framer-motion";
import { useCallback } from "react";

import { CtaLink } from "./CtaLink";
import { PATHS } from "@/constants";

import type { ReactElement } from "react";

const DrawerPanel = styled(Drawer)`
  section > div {
    width: 100vw;
    > div > div {
      background: ${(theme) => theme.colors.primary};
    }
  }
  min-height: 100svh;
  @media ${notDesktop} {
    section {
      inset: 0;
    }
    section > div {
      max-width: 100vw;
      width: 100vw;
    }
  }
`;

const Content = styled(DrawerContent)`
  padding: ${(theme) => theme.spacing.large};
  padding-top: 15vh;
`;

const MotionStack = motion(styled(Stack)`
  flex: 1;
`);
const MotionLink = motion(Link);

const LinkButton = styled(CtaLink)`
  border-radius: calc(${(theme) => theme.radius.small} / 2);
`;

const ContentStack = styled(Stack)`
  height: 100%;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: ${(theme) => theme.spacing.small};
  right: ${(theme) => theme.spacing.small};
  color: white;
`;

const item = {
  animate: { opacity: 1, y: 0 },
  initial: { opacity: 0, y: -10 },
};

export function MobilePanel(): ReactElement | null {
  const [{ menuPanelOpen }, dispatch] = useUi("menuPanelOpen");

  const close = useCallback(() => dispatch("CLOSE_ALL_PANELS"), [dispatch]);

  if (!menuPanelOpen) {
    return null;
  }

  return (
    <Context name="menu">
      <DrawerPanel handleClickOutside={close}>
        <Content>
          <ContentStack kind="vertical" spacing="large">
            <MotionStack
              kind="vertical"
              spacing="large"
              initial="initial"
              animate="animate"
              transition={{
                when: "beforeChildren",
                staggerChildren: 0.15,
                delayChildren: 0.2,
              }}
            >
              {PATHS.map(({ href, title }) => (
                <MotionLink
                  key={href}
                  href={href}
                  variants={item}
                  onClick={close}
                >
                  {title}
                </MotionLink>
              ))}
            </MotionStack>
            <FadeIn>
              <SvgMenuGolf width="100%" />
            </FadeIn>
            <FadeIn>
              <LinkButton button href="#rezerwacja" text="Rezerwacja" />
            </FadeIn>

            <CloseButton kind={["icon", "transparent"]} onClick={close}>
              <SvgCloseMenu />
            </CloseButton>
          </ContentStack>
        </Content>
      </DrawerPanel>
    </Context>
  );
}
