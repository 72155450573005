import { css, mobile, notDesktop, styled } from "@obvio/app";
import { SvgMenu } from "@obvio/svg";
import { Button, Grid } from "@obvio/ui";
import { motion } from "framer-motion";

import { CtaLink } from "../CtaLink";

export const NavElement = styled.nav<{ $visible: boolean }>`
  min-height: var(--nav-height);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  margin: 0 auto;
  background-color: #ffffff;
  ${(_, { $visible }) =>
    $visible
      ? css`
          transform: translateY(0rem);
          width: 100%;
          box-shadow: 0 0 0 0 #e1e1e1;
          border-radius: 0rem;
        `
      : css`
          transform: translateY(1rem);
          width: 95%;
          box-shadow: 0px 4px 30px 0px #e1e1e1;
          border-radius: 4rem;
        `}
  transition: 500ms all ease-in-out;
  @media ${mobile} {
    max-width: 100%;
  }
`;

export const NavContent = styled(Grid)`
  height: var(--nav-height);
  align-items: center;
  margin: 0 auto;
  width: 82rem;
  max-width: calc(100% - 7.5rem);
  @media ${notDesktop} {
    max-width: calc(100% - 4rem);
    grid-template-columns: auto 1fr;
  }
`;

export const Links = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  button {
    padding: 0;
    border: none;
    ${(theme) => theme.links.css}
    :hover {
      background: transparent;
    }
  }
  @media ${notDesktop} {
    display: none;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  a {
    color: white;
  }
`;

export const Additional = styled.div<{ $visible: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  top: 50%;
  z-index: -1;
  background: white;
  border-bottom-left-radius: ${(theme) => theme.radius.big};
  border-bottom-right-radius: ${(theme) => theme.radius.big};
  max-height: 20rem;
  height: ${(_, { $visible }) => ($visible ? "30rem" : "0rem")};

  transition: 500ms all ease-in-out;
  will-change: height;
  h1 {
    font-size: 12.5rem;
  }

  @media ${notDesktop} {
    max-height: 25rem;
    h1 {
      font-size: 8rem;
    }
    border-bottom-left-radius: ${(theme) => theme.radius.small};
    border-bottom-right-radius: ${(theme) => theme.radius.small};
  }
`;

export const NavAdditional = motion(styled(Grid)`
  background: white;
  width: 82rem;
  max-width: calc(100% - 7.5rem);
  margin: 0 auto;
  height: 20rem;
  padding: ${(theme) => theme.spacing.medium} 0;
  align-items: center;
  > p {
    max-width: 24.5rem;
    margin-left: auto;
  }

  @media ${notDesktop} {
    max-width: calc(100% - 4rem);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 25rem;
    > p {
      margin: unset;
      padding-top: ${(theme) => theme.spacing.medium};
      max-width: unset;
    }
  }
`);

export const ButtonLink = styled(CtaLink)`
  display: block;
  @media ${notDesktop} {
    display: none;
  }
`;

export const MenuSvg = styled(SvgMenu)`
  display: none;
  @media ${notDesktop} {
    display: block;
  }
`;

export const NavButton = styled(Button)`
  ${(theme) => theme.buttons.css}

  @media ${notDesktop} {
    ${(theme) => theme.buttons.icon}
    ${(theme) => theme.buttons.transparent}
  }
`;
