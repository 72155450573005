import { Link, styled, Text } from "@obvio/app";
import { SvgCtaArrow } from "@obvio/svg";
import { Button } from "@obvio/ui";
import { motion } from "framer-motion";
import { useCallback } from "react";

import type { TextType } from "@obvio/app/types/theme";
import type { AllowUndefinedForOptional } from "@obvio/utils";
import type { ReactElement, ComponentProps, ReactNode } from "react";

const MotionLink = motion(Link);
const MotionButton = motion(Button);
const ArrowWrap = motion(styled.div`
  margin-left: ${(theme) => theme.spacing.small};
  display: inline-block;
`);

type CtaLinkProps = AllowUndefinedForOptional<{
  href: string;
  text: string;
  button?: boolean;
  className?: string;
  as?: TextType;
}>;

export function CtaLink({
  href,
  className,
  button,
  as,
  text,
}: CtaLinkProps): ReactElement {
  const Wrap = useCallback(
    (
      props: Omit<CtaLinkProps, "text"> &
        ComponentProps<typeof MotionLink> & { children: ReactNode },
      // @ts-expect-error -- whatever
    ) => (button ? <MotionButton {...props} /> : <MotionLink {...props} />),
    [button],
  );
  return (
    <Wrap href={href} initial="hidden" whileHover="hover" className={className}>
      {as ? (
        <Text tag="span" as={as}>
          {text}
        </Text>
      ) : (
        text
      )}
      <ArrowWrap
        variants={{
          hidden: {
            y: 0,
            x: 0,
          },
          hover: {
            y: -2,
            x: 2,
          },
        }}
      >
        <SvgCtaArrow />
      </ArrowWrap>
    </Wrap>
  );
}
