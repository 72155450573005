import { Context, Link, Text, useIsMobile } from "@obvio/app";
import { FadeIn, useUi } from "@obvio/template";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { useState } from "react";

import {
  NavElement,
  NavContent,
  Links,
  ButtonWrap,
  Additional,
  NavAdditional,
  ButtonLink,
  MenuSvg,
  NavButton,
} from "./Nav/components";
import { PATHS } from "@/constants";

import type { ReactElement } from "react";

const additionalContentVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const transition = {
  duration: 0.35,
};

export function Nav(): ReactElement {
  const [scrolled, setScrolled] = useState(false);
  const [, dispatch] = useUi("menuPanelOpen");
  const isMobile = useIsMobile(1025);

  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (v) => {
    if (v > 25) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  });

  return (
    <Context name="white">
      <NavElement $visible={!scrolled}>
        <NavContent templateColumns="auto 1fr 20%">
          <Link href="/">
            <Text tag="span" as="h3">
              Pałac Mała Wieś
            </Text>
          </Link>
          <Links>
            {PATHS.map(({ title, href }) => (
              <Link key={title} href={href}>
                {title}
              </Link>
            ))}
          </Links>
          <ButtonWrap>
            <NavButton
              {...(isMobile
                ? {
                  onClick: () => dispatch("OPEN_MENU_PANEL"),
                }
                : {})}
            >
              <MenuSvg />
              <ButtonLink href="#zarezerwuj" text="Zarezerwuj" />
            </NavButton>
          </ButtonWrap>
        </NavContent>
        <Additional $visible={!scrolled}>
          <FadeIn delay={0}>
            <NavAdditional
              templateColumns="1fr 1fr"
              variants={additionalContentVariants}
              transition={transition}
            >
              <Text tag="h1">Golf</Text>
              <Text>
                Zapraszamy Cię do odkrycia uroku golfa na naszym Driving Range
                pod Warszawą! Nasza strzelnica golfowa oferuje idealne warunki
                oraz otoczenie do doskonalenia umiejętności.
              </Text>
            </NavAdditional>
          </FadeIn>
        </Additional>
      </NavElement>
    </Context>
  );
}
