import { css, Global, styled } from "@obvio/app";
import { ObvioFooter } from "@obvio/ui";

import { Footer } from "./Footer";
import { MobilePanel } from "./MobilePanel";
import { Nav } from "./Nav";

import type { AllowUndefinedForOptional } from "@obvio/utils";
import type { ReactElement } from "react";

const StyledObvioFooter = styled(ObvioFooter)`
  margin: 0 auto;

  > a {
    color: ${(theme) => theme.colors.primary.dark};
  }
`;

const globalStyle = css`
  body {
    background-color: ${(theme) => theme.background};
  }
  html {
    scroll-behavior: smooth;
  }
  ${(theme) => theme.global}
`;

type LayoutProps = AllowUndefinedForOptional<{
  children: ReactElement | ReactElement[];
}>;

export function Layout({ children }: LayoutProps): ReactElement {
  return (
    <>
      <Nav />
      <main>{children}</main>
      <Footer />
      <StyledObvioFooter />
      <MobilePanel />
      <Global styles={globalStyle} />
    </>
  );
}
