import { css, notDesktop } from "@obvio/app";
import { FadeIn } from "@obvio/template";
import { Section } from "@obvio/ui";
import { useMemo } from "react";

import type { ThemeContext } from "@obvio/app";
import type { SectionProps } from "@obvio/ui";
import type { AllowUndefinedForOptional } from "@obvio/utils";
import type { ReactElement } from "react";

const styles = {
  rounded: css`
    border-top-left-radius: ${(theme) => theme.radius.big};
    border-top-right-radius: ${(theme) => theme.radius.big};
    @media ${notDesktop} {
      border-top-left-radius: ${(theme) => theme.radius.small};
      border-top-right-radius: ${(theme) => theme.radius.small};
    }
  `,
};

type GolfSectionProps = Exclude<SectionProps, "context"> &
  AllowUndefinedForOptional<{
    name?: string;
    round?: boolean;
    noFadeIn?: boolean;
    context?: "White" | "Black" | "Green";
  }>;

export function GolfSection({
  round,
  noFadeIn,
  // @ts-expect-error
  context = "White",
  children,
  ...sectionProps
}: GolfSectionProps): ReactElement {
  const outerCss = useMemo(
    () => css`
      position: relative;
      ${round && styles.rounded}
      padding-bottom: 5rem;
      margin-top: -5rem;
    `,
    [round],
  );

  return (
    <Section
      // @ts-expect-error -- ?? Works fine in ts playground
      context={context?.toLowerCase() as ThemeContext}
      outerCss={outerCss}
      {...sectionProps}
      id={sectionProps.name}
    >
      {noFadeIn ? children : <FadeIn>{children as ReactElement}</FadeIn>}
    </Section>
  );
}
