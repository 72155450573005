import {
  mobile,
  notMobile,
  styled,
  useRemToPx,
  Image,
  notDesktop,
} from "@obvio/app";
import { FadeIn } from "@obvio/template";
import { Stack } from "@obvio/ui";
import { useCallback, useMemo } from "react";

import { CarouselBase, CarouselSection } from "./CarouselBase";

import type { ImageAsset } from "@obvio/app";
import type { ReactElement } from "react";

const ImageWrap = styled(Stack)`
  max-height: 35rem;
  height: 60vh;
  width: 100%;
  color: ${(theme) => theme.colors.secondary};
  text-align: left;
  @media ${mobile} {
    height: 40vh;
  }
  @media ${notDesktop} {
    height: 40vh;
  }

  transition: transform 0.3s;
  border-radius: ${(theme) => theme.radius.small};
  overflow: hidden;
`;

type ImageCompProps = {
  image: ImageAsset;
};

export function ImageComp({ image }: ImageCompProps): ReactElement {
  return (
    <ImageWrap>
      <Image img={image} sizes="40vw" />
    </ImageWrap>
  );
}

type ImagesCarouselProps = {
  images: ImageAsset[];
};

const defaultImages: ImageAsset[] = [];

// TODO: If required check if it's natively possible to move non-active slides more to the edges if preView: 2 + origin: "center". Maybe plugins is needed but it's bonkers
export function ImagesCarousel({
  images = defaultImages,
}: ImagesCarouselProps): ReactElement {
  const remToPx = useRemToPx();

  const MemoizedImage = useCallback(
    (image: ImageAsset) => <ImageComp image={image} />,
    [],
  );

  const widths = useMemo(
    () =>
      images.map((image: { ratio?: number }) => {
        const ratio = image.ratio ?? 1;
        return remToPx(35) * ratio;
      }),
    [images, remToPx],
  );
  return (
    <CarouselSection>
      <FadeIn>
        <CarouselBase
          data={images}
          render={MemoizedImage}
          plugins={[
            CarouselBase.plugins.autoWidth(widths),
            ({ on }) => {
              on("detailsChanged", (props) => {
                const current = props.track.details.abs;

                let idx = current % images.length;
                idx = idx < 0 ? images.length + idx : idx;

                for (let i = 0; i < props.slides.length; i++) {
                  const slide = props.slides[i];

                  const block = slide.firstChild as HTMLElement;

                  if (i === idx && block) {
                    block.style.transform = "scale(1)";
                  } else if (block) {
                    block.style.transform = "scale(0.75)";
                  }
                }
              });
            },
          ]}
          options={{
            defaultAnimation: {
              duration: 500,
            },
            autoSwitchTimeout: 2500,

            breakpoints: {
              [notMobile]: {
                loop: true,
                slides: {
                  perView: 2,
                  spacing: remToPx(1),
                  origin: "center",
                },
              },
              [mobile]: {
                loop: true,
                slides: {
                  perView: 2,
                  origin: "center",
                },
              },
            },
          }}
        />
      </FadeIn>
    </CarouselSection>
  );
}
