import { Context, css, Link, notDesktop, styled, Text } from "@obvio/app";
import { Button, Grid, Stack } from "@obvio/ui";

import type { ReactElement } from "react";

const FooterWrap = styled.footer`
  z-index: 1;
  position: relative;
  background-color: ${(theme) => theme.colors.primary.dark};
  height: 43.125rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: -5rem;
  border-top-left-radius: ${(theme) => theme.radius.big};
  border-top-right-radius: ${(theme) => theme.radius.big};

  * {
    color: white;
  }
  @media ${notDesktop} {
    min-height: 100vh;
    height: auto;
  }
`;

const Content = styled(Grid)`
  max-width: calc(100% - 7.5rem);
  padding: 5rem 0;
  margin: 0 auto;
  width: 82rem;
  flex: 1;
  p {
    font-family: ${(theme) => theme.secondaryFont};
  }
  @media ${notDesktop} {
    grid-template-columns: 1fr;
    max-width: calc(100% - 5rem);
  }
  > div {
    overflow: hidden;
  }
`;

const FooterTextWrap = styled(Stack) <{ $full?: boolean }>`
  h4 {
    font-family: ${(theme) => theme.secondaryFont};
    font-size: 1.375rem;
  }
  p {
    font-family: ${(theme) => theme.secondaryFont};
  }
  ${(_, { $full }) =>
    $full &&
    css`
      grid-column: span 2;
      @media ${notDesktop} {
        grid-column: span 1;
      }
    `}
`;

const Title = styled(Text)`
  @media ${notDesktop} {
    font-size: 4rem;
  }
`;

const LinksStack = styled(Stack)`
  flex-wrap: wrap;
  gap: 1rem;
`;

const BottomPanel = styled.div`
  display: flex;
  justify-content: space-between;
  grid-column: span 2;
  @media ${notDesktop} {
    flex-direction: column;
    grid-column: span 1;
  }
`;

const TopText = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`;

type FooterTextProps = {
  head: string;
  description: string;
  full?: boolean;
};

function FooterText({ head, description, full = false }: FooterTextProps) {
  return (
    <FooterTextWrap kind="vertical" $full={full}>
      <Text tag="h4">{head}</Text>
      <Text>{description}</Text>
    </FooterTextWrap>
  );
}

const links = [
  { title: "Regulamin Serwisu", href: "/" },
  { title: "Polityka Prywatności", href: "/" },
  { title: "Pliki Cookies", href: "/" },
  { title: "Instagram", href: "/" },
  { title: "Facebook", href: "/" },
];

export function Footer(): ReactElement {
  return (
    <Context name="green">
      <FooterWrap>
        <Content templateColumns="1fr 1fr" templateRows="1fr auto" gap="large">
          <Title tag="h3" as="h1">
            Golf Mała Wieś
          </Title>
          <TopText templateColumns="1fr 1fr" templateRows="1fr 1fr" gap="large">
            <FooterText
              head="Pałac Mała Wieś"
              description={`Mała Wieś 4005-622
Belsk Duży`}
            />
            <FooterText
              head="Zarezerwuj"
              description={`+48 782 404 0 403
biuro@palacmalawies.pl`}
            />
            <FooterText
              head="Na miejscu"
              description="Po przybyciu do Małej Wsi, udaj się do Recepcji Hotelu w celu wypożyczenia sprzętu oraz zestawu piłek do golfa."
              full
            />
          </TopText>
          <BottomPanel>
            <div>
              <LinksStack>
                {links.map(({ href, title }) => (
                  <Link key={title} href={href}>
                    {title}
                  </Link>
                ))}
              </LinksStack>
            </div>
            <Button
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Wróć na górę strony
            </Button>
          </BottomPanel>
        </Content>
      </FooterWrap>
    </Context>
  );
}
